<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6 col-lg-4">
        <div class="my-5">
          <Logo></Logo>

          <PageTitle :title="'Login'" class="text-center"></PageTitle>

          <form
            @submit.prevent="login"
            :class="isLoading ? 'is-busy' : ''"
            class="my-4"
          >
            <!-- <ErrorMessage v-if="error" :error="error"></ErrorMessage> -->

            <div class="my-3 form-floating">
              <input
                v-model.trim="form.email"
                type="email"
                class="form-control"
                id="usermail"
                placeholder="user@domain.com"
              >
              <label for="usermail">
                Email address<sup class="text-danger">*</sup>
              </label>
            </div>

            <div class="my-3 form-floating">
              <input
                v-model.trim="form.password"
                type="password"
                class="form-control"
                id="userpass"
                placeholder="password"
              >
              <label for="userpass">
                Password<sup class="text-danger">*</sup>
              </label>
            </div>

            <SubmitButton
              :text="'Sign in'"
              :textBusy="'Please wait...'"
              :isLoading="isLoading"
              :disabled="isLoading || isSubmitDisabled"
              class="btn btn-primary"
            ></SubmitButton>
          </form>

          <div class="my-5 pt-5 border-top text-center">
            <div>
              <router-link
                to="/lost-password"
                class="link-primary"
              >Can't remember password?</router-link>
            </div>
            <div class="mt-3">
              <router-link
                to="/register"
                class="link-primary"
              >Or create a new account</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  components: {
    Logo: () => import('@/components/Logo'),
    PageTitle: () => import('@/components/PageTitle'),
    // ErrorMessage: () => import('@/components/message/ErrorMessage'),
    SubmitButton: () => import('@/components/button/SubmitButton'),
  },
  computed: {
    isSubmitDisabled() {
      return this.form.email === '' || this.form.password === '';
    },
  },
  methods: {
    async login() {
      this.isLoading = true;
      this.error = null;
      try {
        const { email, password, redir } = this.form;
        await this.$store.dispatch('auth/login', {
          email,
          password,
          redir,
        });
      } catch (err) {
        const { code, message } = err;
        if (code === 'auth/user-disabled') {
          this.error = new Error(`${message} Please contact our support at support@trainifier.co to resolve this issue.`);
        } else {
          this.error = new Error(message);
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  data() {
    return {
      isLoading: false,
      error: null,
      form: {
        email: '',
        password: '',
        redir: '',
      },
    };
  },
  mounted() {
    if (window.location.hash) {
      let hash = decodeURIComponent(window.location.hash);
      if (hash.indexOf('#') === 0) hash = hash.substring(1);
      this.form.redir = hash;
    }
  },
};
</script>
